import React from 'react';
import {Button, Typography} from '@material-ui/core';
import {useStyles} from "./Styles";


const ServiceTime: React.FC = () => {
    const classes = useStyles();
    const handleClick = () => {
        window.location.href = 'https://us02web.zoom.us/j/6901739158'
    };

    return (
        <section className={classes.section}>
            <Typography variant="h4" className={classes.title}>聚會時間 Service Times</Typography>
            <p></p>
            <div className={classes.worshipInfo}>
                {/* Replace the icons and contact information with your own */}
                <span className={classes.icon}>{/* Insert icon component or image here */}</span>
                <Typography variant="body1">主日崇拜 週日 10:30am-12:00pm</Typography>
            </div>
            <div className={classes.worshipInfo}>
                <span className={classes.icon}>{/* Insert icon component or image here */}</span>
                <Typography variant="body1">兒童主日學 週日 10:30am-12:00pm</Typography>
            </div>
            <div className={classes.worshipInfo}>
                <span className={classes.icon}>{/* Insert icon component or image here */}</span>
                <Typography variant="body1">全教會禱告會 週三 8:00pm-9:00pm</Typography>
            </div>
            <Button className={classes.button} onClick={handleClick}>
                線上聚會
            </Button>
        </section>
    );
};

export default ServiceTime;
