import Navigation from './components/Navigation';
import ContactUs from './components/ContactUs';
import ServiceTime from './components/ServiceTime';
import AboutUs from './components/AboutUs';
import Welcome from "./components/Welcome";
import {useStyles} from "./components/Styles";

const App: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <Navigation />
            <Welcome />
            <AboutUs />
            <ContactUs />
            <ServiceTime />
        </div>
    );
};

export default App;
