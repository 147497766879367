import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    app: {
        margin: '0 auto', // Center the app horizontally
        maxWidth: 1200, // Set the maximum width of the app container
        padding: theme.spacing(2), // Add horizontal padding
    },
    serviceTime: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px', // Adjust the spacing
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#0000EE', // Adjust the link color
    },
    imageOverlay: {
        position: 'relative',
        width: '100%',
        height: '400px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff', // Adjust the text color
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity and color
        zIndex: 1, // Place the overlay behind the text
    },
    content: {
        position: 'relative',
        zIndex: 2, // Place the text on top of the overlay
    },
    welcome_title: {
        fontSize: '50px', // Adjust the font size
        fontWeight: 'bold',
        marginBottom: '16px', // Adjust the spacing
    },
    subtext: {
        fontSize: '18px', // Adjust the font size
    },
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        alignItems: "center",
    },
    navigation: {
        backgroundColor: '#ffffff',
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 9999,
        margin: '0 auto',
        maxWidth: 1200,
        transition: 'background-color 0.3s ease',
    },
    transparent: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background color
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    logoImage: {
        width: 30,
        height: 'auto',
        marginRight: theme.spacing(1),
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    homeButton: {
        color: '#888888',
        fontWeight: 'bold',
        fontSize: '18px',
        marginLeft: theme.spacing(2),
        textTransform: 'none', // Remove button shape
    },
    button: {
        color: '#888888',
        fontWeight: 'bold',
        fontSize: '18px',
        marginLeft: theme.spacing(2),
        border: '1px solid #999999',
        textTransform: 'none', // Remove button shape
    },
    mobileButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    menu: {
        position: 'fixed',
        top: '64px',
        right: '0',
        width: '100%',
        maxWidth: '20%',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '0 0 4px 4px',
        padding: theme.spacing(2),
        zIndex: 9999,
        transform: 'translateX(100%)',
        transition: 'transform 0.3s ease-in-out',
    },
    menuOpen: {
        transform: 'translateX(0)',
    },
    menuItem: {
        display: 'block',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: '#888888',
        fontWeight: 'bold',
        textDecoration: 'none',
        transition: '0.3s',
        '&:hover': {
            color: '#333333',
            backgroundColor: '#f4f4f4',
        },
    },
    header: {
        backgroundColor: '#f7f7f7',
        padding: theme.spacing(2),
        margin: '16px', // Add margin bottom for spacing
    },
    section: {
        backgroundColor: '#f4f4f4',
        padding: theme.spacing(4),
        textAlign: 'center',
        color: '#333333'
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    image: {
        width: '100%',
        maxWidth: 500,
        height: 'auto',
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    modalContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: '#ffffff',
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
        margin: '0 auto',
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    contactUs: {
        backgroundColor: '#f4f4f4',
        padding: theme.spacing(4),
        textAlign: 'center',
    },
    contactInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    worshipInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));
