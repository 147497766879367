import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import {useStyles} from "./Styles";

const ContactUs: React.FC = () => {
    const classes = useStyles();

    const handleFacebookClick = () => {
        const facebookLink = "https://www.facebook.com/groups/efcepping"
        window.open(facebookLink, '_blank');
    };

    const handleTelephoneClick = () => {
        const telephone = '0415112698'
        window.location.href = `tel:${telephone}`;
    };

    const handleEmailClick = () => {
        const email = 'efcepping023@gmail.com'
        window.location.href = `mailto:${email}`;
    };

    const handleAddressClick = () => {
        const address = '2 George Street, Epping, 2121'
        const encodedAddress = encodeURIComponent(address);
        window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    };


    return (
        <div className={classes.contactUs}>
            <Typography variant="h4" className={classes.title}>
                聯絡我們 Contact Us
            </Typography>
            <p></p>
            <div className={classes.contactInfo}>
                {/* Replace the icons and contact information with your own */}
                <span className={classes.icon}>
                    <IconButton onClick={handleAddressClick} color="inherit">
                        <HomeIcon />
                    </IconButton>
                </span>
                <Typography variant="body1">2 George Street, Epping, 2121</Typography>
            </div>
            <div className={classes.contactInfo}>
                <span className={classes.icon}>
                    <IconButton onClick={handleEmailClick} color="inherit">
                        <AlternateEmailIcon />
                    </IconButton>
                </span>
                <Typography variant="body1">efcepping023@gmail.com</Typography>
            </div>
            <div className={classes.contactInfo}>
                <span className={classes.icon}>
                    <IconButton onClick={handleTelephoneClick} color="inherit">
                        <PhoneIcon />
                    </IconButton>
                </span>
                <Typography variant="body1">0415-112-698</Typography>
            </div>
            <div className={classes.contactInfo}>
                <span className={classes.icon}>
                    <IconButton onClick={handleFacebookClick} color="inherit">
                         <FacebookIcon />
                    </IconButton>
                </span>
                <Typography variant="body1"> EFC Epping </Typography>
            </div>
        </div>
    );
};

export default ContactUs;
