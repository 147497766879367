import React, {useEffect, useState} from 'react';
import { Button } from '@material-ui/core';
import { useStyles } from './Styles';
import imageSrc from '../Image/logo.jpg'

const Navigation: React.FC = () => {
    const classes = useStyles();

    const [isTransparent, setIsTransparent] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setIsTransparent(true);
            } else {
                setIsTransparent(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`${classes.navigation} ${isTransparent ? classes.transparent : ''}`}>
            <div className={classes.logo}>
                <img src={imageSrc} alt="Church Logo" className={classes.logoImage} />
                <Button className={classes.homeButton}>愛平基督教會</Button>
            </div>
        </nav>
    );
};

export default Navigation;
