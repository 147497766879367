import React from 'react';
import {useStyles} from "./Styles";
import imageSrc from '../Image/home.jpeg'

interface ImageOverlayProps {
    backgroundImage: string;
    title: string;
    subtext: string;
    englishSubText: string;
}

const ImageOverlay: React.FC<ImageOverlayProps> = ({ backgroundImage, title, subtext, englishSubText }) => {
    const classes = useStyles();

    return (
        <div className={classes.imageOverlay} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className={classes.overlay} />
            <div className={classes.content}>
                <h2 className={classes.welcome_title}>{title}</h2>
                <p className={classes.subtext}>{subtext}</p>
                <p className={classes.subtext}>{englishSubText}</p>
            </div>
        </div>
    );
};

const Welcome: React.FC = () => {
    return (
        <div>
            {/* Other components */}
            <ImageOverlay
                backgroundImage={imageSrc} // Replace with the actual image URL
                title="愛平基督教會"
                subtext="萬民敬拜,榮耀主名"
                englishSubText="All Nations Worship, Glory to His Name"
            />
            {/* Other components */}
        </div>
    );
};

export default Welcome;
