import React from 'react';
import { Typography } from '@material-ui/core';
import '../index.css';
import {useStyles} from "./Styles";



const AboutUs: React.FC = () => {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Typography variant="h4" className={classes.title}>認識我們 About Us</Typography>
            <p></p>
            <div style={{display: 'inline-block', width:'70%'}}>
            <Typography variant="body1" align="center" gutterBottom>
                愛平台福基督教會成立於1998年9月13日，是澳洲台福雪梨地區第二間台福教會。自2003年起，教會積極參與台灣基福／鄉福宣教事工，
                並於每年與雪梨台福基督教會聯合差派二隊短宣隊回臺服事我們骨肉之親，直到2009年共去過下列地點：嘉義東石（鄉福），苗栗頭屋（基福），
                宜蘭壯圍(鄉福)，花蓮真愛台福，基隆山海關台福，中國江蘇、廣州及深圳。2005年並開始與雪梨台福基督教會籌劃開拓於Maroubra地區開設雪梨
                第三間台福教會 -「東雪梨台福基督教會」。 現任牧師為邵海東牧師</Typography></div>
        </section>
    );
};

export default AboutUs;
